$(function(){

	$win = $(window);
	var winW = function(){
		return $win.width();
	}
	var winH = function(){
		return $win.height();
	}
	$bg = $('.top-bg');
	$bg2 = $('.top-bg2');
	$bg3 = $('.top-bg3');
	$bg3_2 = $('.top-bg3_2');
	$catch = $('.txt-catch');
	$side = $('.box-side');
	$openMenu = $('.btn-menu');
	$closeMenu = $('.btn-close');
	$footer = $('.footer');
	$main = $('.main');

	// $openMenu.click(function(){
	// 	$openMenu.addClass('hide');
	// 	$closeMenu.removeClass('hide');
	// 	$side.addClass('open');
	// 	$footer.addClass('nav-open');
	// 	$main.addClass('nav-open');
	// });

	// $closeMenu.click(function(){
	// 	$closeMenu.addClass('hide');
	// 	$openMenu.removeClass('hide');
	// 	$side.removeClass('open');
	// 	$footer.removeClass('nav-open');
	// 	$main.removeClass('nav-open');
	// });

	var BG_W = 1920;
	var BG_H = 1402;
	var IMAGE_RATIO  = 1.45;
	var TOP_V_RATIO = BG_W/BG_H;
	var SMALL_RATIO = 1.61;

	var scene = 0;
	var skipflg = false;

	$win.load(function(){
		$bg.addClass('show');
		calcPos();

		setTimeout(function(){
			$catch.addClass('show');
		},1000);

		function scrollImage(){
			$catch.addClass('hide');
			// $bg.addClass('hide');
			$bg2.addClass('flash');
			$bg3.addClass('show');
			$bg3_2.addClass('show');
			setTimeout(function(){

			},1000);
			scene++;
			calcPos();
			$('.btn-skip').fadeOut();
		}
		function showLogo(){
			$bg.removeClass('move');
			$('.box-top-text').show();
				$('.top-catch2').show();
				$('.nav-side-right').show();
				$('.nav-side-left').show();
			setTimeout(function(){
				$('.top-logo').addClass('show');
				$('.top-catch2').addClass('show');
				// $('.box-top-bnr').addClass('show');
				$('.nav-side-right').addClass('show');
				$('.nav-side-left').addClass('show');
				shining();
				// $bg3_2.addClass('flash');
			},10);




		}


		setTimeout(function(){
			if(!skipflg){
				scrollImage();
			}

		},7000);

		setTimeout(function(){
			if(!skipflg){

				showLogo();
			}
		},10000);



		$('.btn-skip').click(function(){

			skipflg = true;
			scrollImage();
			setTimeout(function(){

				showLogo();
			},3000);
		});

		$('.box-top-text').show();

	});

	function calcPos() {

		// if(winW() < 1200){
		// 	var w = winW()*SMALL_RATIO;
		// } else {
		// 	var w = winW();
		// }
		// var h = w*IMAGE_RATIO;
		// var l = -(w - winW())/2;

		// if(scene == 1){
		// 	var t = -((h*TOP_V_RATIO)-(winH()/2));
		// } else {
		// 	var t = 0;		
		// }
		
		// $bg.css({
		// 	left: l,
		// 	top: t,
		// 	width: w,
		// 	height: h	
		// });

		// $bg3.css({
		// 	left: l,
		// 	top: t,
		// 	width: w,
		// 	height: h	
		// });

	}
	
	function shining(){
		// 
		// var rand = Math.floor( Math.random() * 5000 );
		var rand = 2000;

		// var rand2 = Math.floor( Math.random() * 500 );

		setTimeout(function(){
			$bg3_2.addClass('flash');
		},rand);

		setTimeout(function(){
			$bg3_2.removeClass('flash');
			shining();
		// },rand+rand2+1000);
		},rand+1500);

	}



	$win.resize(calcPos);
	

	

});